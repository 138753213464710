@import '../common';

.Story-Trend {
  margin: -15px auto 0;

  @media only screen and (min-width: $screen-sm) {
    padding: 5px 20px 0;
  }

  @media only screen and (min-width: $screen-lg) {
    padding: 0 65px;
  }

  .chq-nav {
    padding: 5px 10px;
  }

  &.stories-video-editor-modal {
    border-radius: 6px 6px 0 0;
    height: 100vh;
    margin: 0;
    max-width: 100vw;
    overflow: scroll;
    padding: 0;
    width: 100vw;
  }

  .Story {
    width: 100%;

    &__answer {
      .chq-edi-op {
        font-size: 20px;
        font-weight: $font-weight-normal;
        max-height: 100%;
        overflow: hidden;
      }

      .chq-btn {
        align-items: center;
        background-color: $color-background-darker;
        border: 0;
        border-radius: 25px;
        color: $color-black;
        display: flex;
        margin-bottom: 6px;
        padding: 7px 25px;

        &:hover {
          border: 0;
        }

        svg {
          height: 16px;
          margin-right: 10px;
          margin-top: -3px;
          width: 16px;
        }

        path {
          fill: $color-black;
        }
      }
    }

    &:hover {
      .Story__answer {
        display: block;
      }
    }
  }

  .share-button {
    display: none;

    @media only screen and (min-width: $screen-md) {
      display: block;
    }
  }

  .FeedItem__preview__header {
    @media only screen and (min-width: $screen-sm) {
      padding-right: 35%;
    }
  }

  .Stories {
    &__container {
      border-radius: $size-border-radius;
      box-shadow: none;
      padding: 15px;

      @media only screen and (min-width: $screen-xs) {
        background-color: $color-white;
        padding-bottom: 8px;
      }

      @media only screen and (min-width: $screen-sm) {
        box-shadow: .5px .5px 3px $color-black-alpha-20;
      }

      .FeedItem__footer {
        margin-top: 15px;
      }
    }

    &__Button {
      align-items: center;
      display: flex !important;
      margin-bottom: 15px;
      margin-top: 15px;
      padding: 0 15px;

      @media only screen and (min-width: $screen-sm) {
        padding: 0;
      }

      @media only screen and (min-width: $screen-md) {
        display: none !important;
        margin-top: 0;
      }

      .chq-btn {
        border: 1px solid $color-primary-green;
        box-shadow: 0 2px 3px $color-black-alpha-20;
        font-size: 14px;
        font-weight: $font-weight-normal;
        padding: 10px;
        width: 100%;

        svg {
          margin-right: 5px;
        }
      }

      .QuickOptions,
      .share-button {
        display: block;
        font-size: 14px;
        font-weight: $font-weight-normal;
        margin-left: 0;
        opacity: 1;
        position: initial;
        width: 100%;
      }

      .QuickOptions {
        margin-left: 10px;
      }

      .share-button {
        padding: 10px;
      }
    }

    &__title-container {
      display: flex;
      justify-content: space-between;
      padding: 0;

      &__gallery {
        align-items: center;
        background-color: transparent;
        border: 0;
        color: $color-link-blue;
        display: flex;
        font-weight: $font-weight-normal;
        margin: 0;

        &:hover {
          cursor: pointer;
        }

        path {
          fill: $color-link-blue;
        }

        svg {
          margin-right: 5px;
        }
      }

      &__title {
        margin: 0;
      }
    }

    .Story-Stack {
      .Story__creator {
        position: initial;
        width: 100%;

        .chq-tmb {
          height: 45px;
          margin-top: 4px;
          min-width: 45px;
          width: 45px;
        }
      }

      .chq-edi-op {
        margin-top: 15px;
      }
    }

    .PhotoGroup__thumbnail {
      padding: 5px;
      position: relative;

      .story--image {
        border-radius: $size-border-radius;
        display: block;
        margin: 0 auto;
        max-height: 540px;
        max-width: 100%;
        min-height: 220px;
        object-fit: cover;
        width: 100%;
      }

      &:hover {
        cursor: pointer;

        .background-container {
          box-shadow: 0 0 6px $color-black-alpha-40;
        }
      }
    }
  }

  &--right {
    .chq-btn {
      border: 1px solid $color-primary-green;
      box-shadow: -2px 2px 3px $color-black-alpha-20;
      font-size: 18px;
      font-weight: $font-weight-light;
      margin-bottom: 25px;
      padding: 10px;
      width: 100%;
    }
  }

  &--label-container {
    margin-left: 40px;
    width: 90px;
  }

  &--text {
    align-items: center;
    color: $color-primary-font;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 15px;
  }

  &--qr {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);
    box-sizing: border-box;
    display: flex;
    height: 165px;
    margin-bottom: 25px;
    position: relative;

    .Subprompt-Arrow {
      display: flex;
      height: 35px;
      margin-left: 30px;
      margin-top: 10px;
      transform: scaleX(-1);
      width: 35px;

      path {
        fill: $color-primary-font;
      }
    }
  }

  &--qr-code {
    display: flex;
    margin-left: 151px;
    margin-top: 17px;
    position: absolute;
  }

  .EmptyResults {
    margin-top: 0;
    padding-bottom: 70px;
  }

  .NewContent {
    padding: 12px;
  }

  .QuickOptions {
    .transparent-button {
      margin-top: 5px;

      &:focus {
        outline: none;
      }
    }
  }

  .CommentListItem {
    .QuickOptions {
      .transparent-button {
        margin-top: 0;
      }
    }
  }

  .FeedItem__preview {
    .QuickOptions--Actions {
      position: absolute;
    }
  }

  .Feed--column {
    background-color: $color-white;
    margin-top: -15px;
    padding: 0;

    @media only screen and (min-width: $screen-sm) {
      background-color: transparent;
      margin-top: 0;
      padding: 0 15px;
    }
  }

  .ChildPrompts {
    box-shadow: none;
    margin-top: 15px;

    @media only screen and (min-width: $screen-sm) {
      box-shadow: $box-shadow-default;
      margin-top: 25px;
    }

    &-Title {
      color: $color-primary-font;

      @media only screen and (min-width: $screen-sm) {
        color: rgba($color-primary-font, .7);
      }
    }
  }

  .New-Story-Trend {
    .Story {
      position: relative;

      &-Header {
        background-size: cover;
        color: $color-white;
        min-height: 365px;

        @media only screen and (min-width: $screen-sm) {
          border-radius: $size-border-radius;
        }

        &__Overlay {
          background: linear-gradient(0deg, $color-black-alpha-0 0%, $color-black-alpha-80 100%);
          background-color: $color-black-alpha-40;
          bottom: 0;
          left: 0;
          padding: 20px 25px 25px;
          position: absolute;
          right: 0;
          top: 0;

          @media only screen and (min-width: $screen-sm) {
            border-radius: $size-border-radius;
          }

          .WatchIntroVideo {
            background-color: rgba($color-background-darker, .7);
            border-radius: 24px;
            bottom: 75px;
            color: $color-black;
            font-size: $font-size-nav;
            font-weight: $font-weight-heavy;
            left: 16px;
            padding: 12px 20px;
            position: absolute;

            @media only screen and (min-width: $screen-md) {
              display: none !important;
            }
          }

          &-AiOverlay {
            background: linear-gradient(to bottom, $start-gradient-color, #04204e);
            bottom: 0;
            left: 0;
            margin-top: -5px;
            padding: 20px 25px 25px;
            position: absolute;
            right: 0;
            top: 0;

            @media only screen and (min-width: $screen-sm) {
              border-radius: $size-border-radius;
              margin-top: 0;
            }
          }
        }

        .ai-assisted-prompt {
          align-items: center;
          background: linear-gradient(90deg, $color-primary-yellow, #87ceeb);
          border-radius: 20px;
          color: $color-white;
          display: inline-flex;
          font-size: $font-size-nav;
          font-weight: $font-weight-heavy;
          margin-bottom: 15px;
          padding: 5px 20px;

          img {
            filter: brightness(0) invert(1);
            height: 16px;
            margin-right: 8px;
            width: 16px;
          }
        }

        .QR {
          bottom: 25px;
          position: absolute;
          right: 25px;
        }

        .PromptShareOptions__Buttons {
          bottom: 25px;
          left: 25px;
          position: absolute;

          .chq-pbn  {
            @include square(40px);
            background-color: $color-dark-blue-alpha-80;
            display: flex;
            margin-right: 5px;
            padding: 5px;
          }

          .Slack-Image {
            @include square(24px);
          }
        }

        .DisablePosition {
          position: static;
        }
      }

      .FeedItem__preview__title {
        &__Story {
          .Title {
            color: $color-white;
            font-size: $font-size-28;
            padding-right: 0;

            a {
              color: $color-white;
            }
          }
        }

        &__Summary {
          -webkit-box-orient: vertical; // sass-lint:disable-line no-vendor-prefixes
          -webkit-line-clamp: 3; // sass-lint:disable-line no-vendor-prefixes
          display: -webkit-box; // sass-lint:disable-line no-vendor-prefixes
          font-size: $font-size-large;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: pre-line;
        }
      }

      .Edit-Btn {
        position: absolute;
        right: 25px;
        top: 25px;

        path {
          fill: $color-white;
        }
      }
    }

    .InstructionalVideo {
      background-position: center;
      border-radius: 600px;
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      position: relative;

      &:hover {
        cursor: pointer;
      }

      &--Title {
        color: $color-white;
        font-size: $font-size-large;
        left: 15px;
        margin: 0;
        position: absolute;
        top: 15px;
      }

      &--Video {
        border-radius: $size-border-radius;
        max-height: 500px;
        object-fit: cover;
        width: 100%;

        &:fullscreen {
          object-fit: contain;
        }
      }
    }

    .Stories-Container {
      background-color: $color-white;
      border-radius: $size-border-radius;
      box-shadow: none;
      margin-top: 5px;
      padding: 10px 15px;

      @media only screen and (min-width: $screen-sm) {
        box-shadow: $box-shadow-default;
        margin-top: 15px;
        padding: 20px;
      }

      @media only screen and (min-width: $screen-md) {
        margin-top: 25px;
      }

      &__Header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        &--Title {
          color: $color-primary-font;
          font-size: $font-size-normal;
          font-weight: $font-weight-heavy;
          margin: 0;

          @media only screen and (min-width: $screen-sm) {
            color: rgba($color-primary-font, .7);
          }
        }
      }
    }

    .QuickOptions__open-dropdown__wrapper {
      path {
        fill: $color-white;
      }
    }

    .storytelling-guide {
      align-items: center;
      background-color: $start-gradient-color;
      border-radius: 6px;
      box-sizing: border-box;
      color: $color-white;
      display: flex;
      flex-direction: column;
      max-width: 400px;
      padding: 15px;

      @media screen and (min-width: $screen-md) {
        padding: 30px;
      }

      @media screen and (min-width: $screen-xl) {
        min-width: 400px;
      }

      .guide-title {
        -webkit-background-clip: text; // sass-lint:disable-line no-vendor-prefixes
        -webkit-text-fill-color: transparent; // sass-lint:disable-line no-vendor-prefixes
        background: linear-gradient(to right, #ffd24b, #8cb4d6);
        background-clip: text;
        font-size: $font-size-22;
        margin: 0;
        text-align: center;

        @media screen and (min-width: $screen-sm) {
          font-size: $font-size-24;
        }
      }

      .guide-paragraph {
        align-items: center;
        display: flex;
        margin-bottom: 15px;
        padding: 0;

        @media screen and (min-width: $screen-sm) {
          margin-bottom: 0;
          padding: 15px 5px;
        }

        &__sub {
          font-size: $font-size-large;
          margin-bottom: 15px;
          margin-top: 15px;
          max-width: 280px;
          text-align: center;

          @media screen and (min-width: $screen-sm) {
            font-size: $font-size-xl;
          }
        }

        span {
          font-size: $font-size-large;
        }

        svg {
          @include square(35px);
          margin-right: 25px;
          min-width: 35px;
        }
      }
    }

    .arrow-icon {
      @include square(24px);
      align-items: center;
      margin-left: 8px;
      position: absolute;
      transform: rotate(180deg);
      transition: transform .3s ease;
    }
  }

  .InstructionalVideo {
    background-position: center;
    border-radius: 600px;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    position: relative;

    &:hover {
      cursor: pointer;
    }

    &--Title {
      color: $color-white;
      font-size: $font-size-large;
      left: 15px;
      margin: 0;
      position: absolute;
      top: 15px;
    }

    &--Video {
      border-radius: $size-border-radius;
      max-height: 500px;
      object-fit: fill;
      width: 100%;

      &:fullscreen {
        object-fit: contain;
      }
    }
  }
}

.Story-Header {
  &__Overlay {
    .PromptShareDisabledTooltip {
      bottom: 25px;
      left: 25px;
      position: absolute;

      .chq-ttp {
        .chq-ttp--bbl {
          bottom: -60px;
          left: 50% !important;
          text-align: center;
          top: calc(100% + 16px) !important;
          transform: translate(-50%);
          width: 230px;

          .chq-ttp--tri {
            left: 47% !important;
          }
        }
      }
    }
  }
}
